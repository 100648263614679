import TextEditor from './TextEditor'
import TitleBar from './TitleBar'
import React from 'react';
import { getAuth } from 'firebase/auth';

console.log(await getAuth())

function App() {
  return (
  <div>
    <TitleBar />
    <TextEditor />
  </div>
)}

export default App;
